import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Logo, Menu, ArrowRight, AppStore, PlayStore } from 'components/svgs'
import ContentWrapper from 'components/ContentWrapper'
import { scrollTo } from 'ui/utils/scrollTo'
import { IconArrow2 } from '@creditas-ui/icons'
import {
  Container,
  CreditasContainer,
  DownloadAppContainer,
  ScrollButton,
  ScrollLink,
  IconWrapper,
} from './styles'
import Submenu from './components/Submenu'
import { ItemWrapper } from './components/Submenu/styles'
import MenuSection from './components/MenuSection'

// eslint-disable-next-line max-lines-per-function
const Header = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulCategory(
        filter: { isVisible: { eq: true }, isSubcategory: { eq: false } }
      ) {
        edges {
          node {
            id
            slug
            title: displayText
            sectionSubcategories {
              label
              showLabel
              categories {
                title
                slug
                displayText
                isSubcategory
              }
            }
          }
        }
      }
    }
  `)

  const {
    allContentfulCategory: { edges: categories },
  } = data
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [activeCategory, setActiveCategory] = useState(null)

  const toggleMenu = () => {
    setMenuIsOpen(prev => !prev)
  }

  return (
    <Container>
      <CreditasContainer maxWidth="lg">
        <nav>
          <ContentWrapper className="contentWrapper">
            <a
              className="logo"
              href="https://creditas.com"
              target="_blank"
              rel="noreferrer"
            >
              <Logo />
            </a>
            <ul>
              <li className="menu-item-desktop">
                <Link to="/" activeClassName="menu-item-active">
                  Home
                </Link>
              </li>
              {categories?.map(({ node: category }) => {
                const hasSubcategories = category.sectionSubcategories?.length

                if (hasSubcategories) {
                  return (
                    category?.title && (
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                      <li
                        key={category.id}
                        className="menu-item-desktop"
                        onFocus={() => setActiveCategory(category.id)}
                        onMouseOver={() => setActiveCategory(category.id)}
                        onMouseLeave={() => setActiveCategory(null)}
                      >
                        <Link
                          activeClassName="menu-item-active"
                          className="category-link"
                        >
                          {category.title}
                          <IconWrapper
                            isVisible={
                              activeCategory === category.id &&
                              category.sectionSubcategories?.length > 0
                            }
                          >
                            <IconArrow2 size={24} color="#4f9e00" />
                          </IconWrapper>
                        </Link>
                        {activeCategory === category.id && (
                          <Submenu sections={category.sectionSubcategories} />
                        )}
                      </li>
                    )
                  )
                }

                return (
                  category?.title && (
                    <li key={category.id} className="menu-item-desktop">
                      <Link
                        to={`/${category.slug}`}
                        activeClassName="menu-item-active"
                        className="category-link"
                        onMouseEnter={() => setActiveCategory(category.id)}
                        onMouseLeave={() => setActiveCategory(null)}
                      >
                        {category.title}
                      </Link>
                    </li>
                  )
                )
              })}
            </ul>
            <div />
            <ScrollButton
              label="Subscribe"
              actionType="scrollTo"
              variant="defaultButton"
              actionHref="#investorForm"
            />
            <Menu className="menu" onClick={toggleMenu} />
          </ContentWrapper>

          {menuIsOpen && (
            <div className="suspended_menu">
              <ul>
                <li>
                  <Link to="/" onClick={() => setMenuIsOpen(false)}>
                    Home
                    <ArrowRight />
                  </Link>
                </li>
                {categories?.map(({ node: category }) =>
                  category.title ? (
                    <li key={category.id}>
                      <Link
                        to={`/${category.slug}`}
                        activeClassName="menu-item-active"
                        onClick={() => setMenuIsOpen(false)}
                      >
                        {category.title}
                        <ArrowRight />
                      </Link>
                      {category.sectionSubcategories?.length &&
                        category.sectionSubcategories.map(section => (
                          <ItemWrapper key={section.label}>
                            <MenuSection {...section} />
                          </ItemWrapper>
                        ))}
                    </li>
                  ) : null,
                )}
                <li className="menu-item-desktop">
                  <ScrollLink
                    activeClassName="menu-item-active"
                    onClick={() => {
                      setMenuIsOpen(false)
                      scrollTo('#investorForm')
                    }}
                  >
                    Subscribe
                  </ScrollLink>
                </li>
              </ul>
              <DownloadAppContainer>
                <span>Mais praticidade? Baixe nosso app:</span>
                <div>
                  <div className="app">
                    <a
                      href="https://apps.apple.com/br/app/creditas/id1270180256"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <AppStore />
                    </a>
                  </div>
                  <div className="app">
                    <a
                      href="https://play.google.com/store/apps/details?id=br.com.creditas.mobile&hl=pt"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PlayStore />
                    </a>
                  </div>
                </div>
              </DownloadAppContainer>
            </div>
          )}
        </nav>
      </CreditasContainer>
    </Container>
  )
}

export default Header
