import { useStaticQuery, graphql } from 'gatsby'
import { Wrapper, ItemLink, Title } from './style'

const Menu = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulCategory(sort: { order: ASC, fields: id }) {
        edges {
          node {
            id
            title
            slug
          }
        }
      }
    }
  `)

  const {
    allContentfulCategory: { edges: categories },
  } = data

  return (
    <Wrapper>
      <ItemLink to="/">
        <Title>Home</Title>
      </ItemLink>
      {categories?.map(({ node: category }) =>
        category.title ? (
          <ItemLink to={`/${category.slug}`} key={`menu-${category.slug}`}>
            <Title>{category.title}</Title>
          </ItemLink>
        ) : null,
      )}
    </Wrapper>
  )
}

export default Menu
